import { Button } from '@/components/base/Button'
import { MONTSERRAT } from '@/config/font'
import { AuthStatus } from '@/state/authentication'
import useStore from '@/state/store'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

const Custom404Page = () => {
  const router = useRouter()
  const { authentication } = useStore()
  const [bgColor, setBgColor] = useState<string>('bg-bg')
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (authentication.authStatus === AuthStatus.SignedIn)
      setBgColor('bg-white')
  }, [authentication])

  return (
    <>
      <div
        className={`flex flex-row justify-center h-[60vh] ${MONTSERRAT.className}`}
      >
        <div className="self-center">
          <h1 className="md:text-[200px] sm:text-[86px] text-[86px] text-center z-0 font-thin">
            {formatMessage({ id: 'page.generic.oops' })}
          </h1>
          <h2
            className={`md:w-[30rem] mr-auto ml-auto ${bgColor} md:text-[30px] text-center font-extralight`}
          >
            {formatMessage({ id: 'page.generic.notFound' })}
          </h2>
          <div className="text-center w-full md:mt-10 mt-6">
            <Button
              onClick={() => {
                router.push('/')
              }}
              className="!block mr-auto ml-auto !pl-8 !pr-8 !text-xl font-extralight"
              label={formatMessage({ id: 'page.generic.backToHome' })}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default Custom404Page
